import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 50px;
  background-color: #E0FFFF;
  text-align: center;
`;

const PackageList = styled.ul`
  list-style-type: none; /* Removes the default bullet points */
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center items horizontally */
`;

const PackageItem = styled.li`
  margin: 10px 0; /* Adds spacing between list items */
  font-size: 1.2rem; /* Increases the font size for better readability */
  text-align: left; /* Aligns the text to the left */
  width: fit-content; /* Keeps the width just enough to fit the text */
`;

const Shop = () => {
  return (
    <Container>
      <h1>Shop</h1>
      <p>Here are our most common configurations of MDX Software that companies lease:</p>
      <h2>Our Most Popular Package Options</h2>
      {/* Updated list with styling */}
      <PackageList>
        <PackageItem>1 User, 1 Office / 3 months</PackageItem>
        <PackageItem>2 Users, 1 Office / 1 year</PackageItem>
        <PackageItem>5 Users, 1 Office / 1 year</PackageItem>
        <PackageItem>Multi-User, Multi-Office / 1 year</PackageItem>
        <PackageItem>Free Trial of the Full MDX Product – 15 days</PackageItem>
      </PackageList>
      <h2>Please contact sales for a quote.</h2>
      <h4>Email: sales@mdxsoftware.com</h4>
      <h4>Phone: +1 (573) 446-3221</h4>
      <h2>Pricing for our Full Product: Curved & Straight Steel Bridge Design & Rating (the complete package)</h2>
      <p>Lease Licenses: (3-month minimum installation period)</p>
      <p>(Includes technical support & updates). Supports multiple concurrent seats for Line Girder and Girder System Analysis. Please contact sales for a quote.</p>
      <h2>Pricing for Line Girder Design & Rating Package (limited to line girder analysis)</h2>
      <p>Lease Licenses: (3-month minimum installation period)</p>
      <p>(Includes technical support and updates). Supports multiple concurrent seats for Line Girder Analysis. Please contact sales for a quote.</p>
      <iframe src="https://form.jotform.com/231925494351156" width="100%" height="1700px" frameBorder="0" title="Shop Form"></iframe>
    </Container>
  );
};

export default Shop;
