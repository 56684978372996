// src/components/Features.jsx
import React from 'react';
import styled from 'styled-components';
import geometry from './geometry.png';
import coordinates from './coordinates.png';
import trucks from './trucks.png';
import grid from './grid.png';

// Container for the whole features section
const FeaturesContainer = styled.div`
  padding: 50px;
  text-align: center;
  background-color: #f9f9f9;
`;

// Title styling
const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
`;

// Container for individual features
const FeaturesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

// Individual feature block styling
const Feature = styled.div`
  flex: 1 1 300px;
  max-width: 200px;
  margin: 20px;
  text-align: left;
  background: #87CEEB;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s;
  
  &:hover {
    transform: translateY(-10px);
  }
`;

// Image container for logos
const FeatureLogo = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
`;

// Heading for feature title
const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

// Paragraph for feature description
const FeatureDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
`;

const Features = () => {
  return (
    <FeaturesContainer>
      <Title>Features</Title>
      <FeaturesRow>
        <Feature>
        <FeatureLogo src={geometry} alt="Geometry Logo" />
          <FeatureTitle>Timesaving geometry generation</FeatureTitle>
          <FeatureDescription>
            For laying out parallel/concentric girder systems, including those with variable horizontal curvature and skewed supports
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureLogo src={coordinates} alt="Coordinates Logo" />
          <FeatureTitle>Flexible nodal coordinate input feature</FeatureTitle>
          <FeatureDescription>
            For accommodating complex girder system framing plans and roadway layouts
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureLogo src={trucks} alt="Trucks Logo" />
          <FeatureTitle>Standard & User-Definable Trucks</FeatureTitle>
          <FeatureDescription>
            Includes rail loading
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureLogo src={grid} alt="Analysis Logo" />
          <FeatureTitle>Girder System Analysis</FeatureTitle>
          <FeatureDescription>
            Use the grid (grillage) model, or plate and eccentric beam finite element model
          </FeatureDescription>
        </Feature>
      </FeaturesRow>
    </FeaturesContainer>
  );
};

export default Features;
