// src/components/ImageCarousel.jsx
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import mdx1 from './mdx.jpg';
import mdx2 from './mdx2.jpg';
import mdx3 from './mdx3.jpg';
import mdx4 from './mdx4.jpg';

// Custom styled component for the slider container
const CarouselContainer = styled.div`
  margin: 50px auto;
  width: 80%;
`;

const ImageCarousel = () => {
  // Array of images to use in the carousel
  const images = [mdx1, mdx2, mdx3, mdx4];

  // Settings for the slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Enable left and right arrows
  };

  return (
    <CarouselContainer>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index + 1}`} style={{ width: '100%', borderRadius: '10px' }} />
          </div>
        ))}
      </Slider>
    </CarouselContainer>
  );
};

export default ImageCarousel;
