import React from 'react';
import styled from 'styled-components';
import HeroSection from '../components/HeroSection';
import Features from '../components/Features';
import Highlights from '../components/Highlights';
import ImageCarousel from '../components/ImageCarousel';
import LatestNews from '../components/LatestNews';
import Footer from '../components/Footer';

// Create a styled container with a light blue background color
const HomeContainer = styled.div`
  background-color: #ADD8E6; /* Light blue background color */
  min-height: 100vh; /* Ensures the background color covers the full viewport height */
`;

const Home = () => {
  return (
    <HomeContainer>
      <HeroSection />
      <Features />
      <Highlights />
      <ImageCarousel />
      <LatestNews />
      <Footer />
    </HomeContainer>
  );
};

export default Home;
