import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link component from react-router-dom
import mdx1 from './mdx.jpg';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
`;

const ImageContainer = styled.div`
  background-image: url(${mdx1});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeroText = styled.h1`
  font-size: 5rem;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Link)` // Style the Link component like a button
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none; // Remove underline from the link
  
  &:hover {
    background-color: #0056b3;
  }
`;

const HeroSection = () => {
  return (
    <HeroContainer>
      <ImageContainer>
        <HeroText>MDX SOFTWARE THE STEEL BRIDGE DESIGN SOLUTION</HeroText>
      </ImageContainer>
      {/* Separate container for the buttons below the image */}
      <ButtonContainer>
        <StyledButton to="/free-trial">Free Trial</StyledButton>
        <StyledButton to="/shop">Purchase</StyledButton>
      </ButtonContainer>
    </HeroContainer>
  );
};

export default HeroSection;
