// src/components/Navbar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  background-color: #87CEEB;
  padding: 2rem;
  display: flex;
  justify-content: space-around;
`;

const NavLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 1.2rem;

  &:hover {
    color: #ddd;
  }
`;

const Navbar = () => {
  return (
    <NavbarContainer>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/free-trial"></NavLink>
      <NavLink to="/features">Features</NavLink>
      <NavLink to="/shop">Shop</NavLink>
      <NavLink to="/support">Support</NavLink>
      <NavLink to="http://support.mdxsoftware.com">Support Login</NavLink>
    </NavbarContainer>
  );
};

export default Navbar;
