// src/components/Highlights.jsx
import React from 'react';
import styled from 'styled-components';

const HighlightsContainer = styled.div`
  padding: 150px;
  text-align: center;
  background-color: #eef1f5;
`;

const HighlightsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

const HighlightBox = styled.div`
  flex: 1 1 300px;
  max-width: 300px;
  margin: 20px;
  padding: 40px;
  background: #87CEEB;

  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    background-color: #f0f8ff;

    & .highlight-content {
      transform: translateY(-100%);
    }

    & .hover-content {
      transform: translateY(0);
    }
  }
`;

const HighlightTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
`;

const HighlightDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
`;

const HoverContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  transition: transform 0.3s;
  transform: translateY(100%);
`;

const Highlights = () => {
  return (
    <HighlightsContainer>
      <h2>Highlights</h2>
      <HighlightsRow>
        <HighlightBox>
          <div className="highlight-content">
            <HighlightTitle>Innovative Design Feature</HighlightTitle>
          </div>
          <HoverContent className="hover-content">
            <HighlightDescription>
              Automatically finds optimal design for girders and braces
            </HighlightDescription>
          </HoverContent>
        </HighlightBox>
        <HighlightBox>
          <div className="highlight-content">
            <HighlightTitle>Quality Software</HighlightTitle>
          </div>
          <HoverContent className="hover-content">
            <HighlightDescription>
              Comparable accuracy to AASHTOWARE at an affordable price
            </HighlightDescription>
          </HoverContent>
        </HighlightBox>
        <HighlightBox>
          <div className="highlight-content">
            <HighlightTitle>Highly Visual</HighlightTitle>
          </div>
          <HoverContent className="hover-content">
            <HighlightDescription>
              Improved focus on visualizations to improve readability and enhance your productivity
            </HighlightDescription>
          </HoverContent>
        </HighlightBox>
      </HighlightsRow>
    </HighlightsContainer>
  );
};

export default Highlights;
