// src/pages/FreeTrial.jsx
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 50px;
  text-align: center;
`;

const FreeTrial = () => {
  return (
    <Container>
      <h1>Free Trial</h1>
      <p>How to Get a Free 15-Day Trial of The Complete MDX Package:</p>
      <p>
        To qualify for a free 15-day trial you must be an employee of an engineering company, government department of transportation, or professional engineering organization, with an email address and telephone number, and be located within the U.S.
      </p>
      <p>
        One trial is allowed per engineering company, government department of transportation, or professional engineering organization. Fill out the following form including signature, and click “Submit” to download. You then will receive download instructions via email.
      </p>
      <iframe src="https://form.jotform.com/232278617375161" width="100%" height="2500px" frameBorder="0" title="Free Trial Form"></iframe>
    </Container>
  );
};

export default FreeTrial;
