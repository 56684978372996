// src/components/Footer.jsx
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 50px 0;
  text-align: center;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const FooterText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterText>
          © 2024 MDX Software. All rights reserved. | Contact us at info@mdxsoftware.com
        </FooterText>
        <FooterText>
          Designed by MDX Software Team
        </FooterText>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
