// src/pages/Support.jsx
import React from 'react';
import styled from 'styled-components';


const Decor = styled.div`
  background-color: #E0FFFF;
`;

const Support = () => {
  return (
    <Decor>
      <div style={{ padding: '50px', textAlign: 'center'}}>
        <h1>Support</h1>
        <iframe src="https://form.jotform.com/232067491380153" width="100%" height="2500px" frameBorder="0" title="Support Form"></iframe>
      </div>
    </Decor>
  );
};

export default Support;
