// src/pages/Features.jsx
import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';

const Container = styled.div`
  padding: 50px;
  background-color: #E0FFFF;
  text-align: left;
`;

const Features = () => {
  return (
    <Container>
      
      <h2>MDX Features</h2>
      <ul>
        <li>Timesaving geometry generation feature for laying out parallel/concentric girder systems, including those with variable horizontal curvature and skewed supports</li>
        <li>Flexible nodal coordinate input feature for accommodating complex girder system framing plans and roadway layouts</li>
        <li>Standard trucks and user-definable trucks (including rail loading)</li>
        <li>Girder system analysis using grid (i.e. grillage) model, or plate and eccentric beam finite element model</li>
        <li>Line girder (i.e. continuous beam analysis) approach also an option</li>
        <li>Lane loading on influence surfaces or wheel load distribution on influence lines</li>
        <li>Up to 20 spans, 60 girders</li>
        <li>Steel plate I-girders, box girders, rolled shapes</li>
        <li>Any girder web profile</li>
        <li>Bearing stiffeners, intermediate transverse web stiffeners, longitudinal web stiffeners, box girder bottom flange stiffeners</li>
        <li>Uniform or hybrid steel girders</li>
        <li>Girder design generator for ASD, LFD, LRFD</li>
        <li>Bolted web splice design for LFD, LRFD</li>
        <li>Shear connector design</li>
        <li>Weld design</li>
        <li>Camber data and live load deflections</li>
        <li>Incremental stress and deflection tables from slab pour sequence analysis</li>
        <li>Performance ratio output</li>
        <li>Girder rating according to ASD, LFD, LRFD</li>
        <li>Variety of bracing types</li>
        <li>Shape selection for bracing members</li>
        <li>English or metric units, including hard conversion</li>
      </ul>
      <h2>New Features</h2>
      <ul>
        <li>Cloud licensing</li>
        <li>Microsoft.Net interface</li>
        <li>No driver installation needed</li>
      </ul>
      <p>Copyright © 2024 MDX Software – OnePress theme by FameThemes</p>
    </Container>
  );
};

export default Features;
