// src/components/LatestNews.jsx
import React from 'react';
import styled from 'styled-components';

const NewsContainer = styled.div`
  padding: 50px;
  text-align: left;
  background-color: #87CEEB;
  margin-top: 50px;
`;

const NewsTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const NewsContent = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
`;

const LatestNews = () => {
  return (
    <NewsContainer>
      <NewsTitle>Latest News</NewsTitle>
      <NewsContent>
        On June 10, the U.S. Department of Transportation (USDOT) Federal Highway Administration (FHWA) released a Notice of Funding Opportunity (NOFO) for $2.36 billion in FY 2022 through the competitive Bridge Investment Program (BIP). Newly established by the Bipartisan Infrastructure Law (BIL/P.L. 117-58), the program will provide $12.5 billion over the next five years to reduce the number of the nation’s bridges in poor condition or, in fair condition, but at risk of falling into poor condition within the next three years. NACo worked tirelessly to ensure this additional funding that can be accessed directly by counties was included in the final package.

        <br /><br />

        Counties are eligible to apply for BIP funding directly through USDOT, as well as to bundle bridge projects into one application. Twenty million is reserved for the planning application category outlined below. In total, the NOFO is soliciting applications for three different funding categories:

        <ul>
          <li>Planning: For projects requiring technical assistance to become eligible for BIP capital construction funds | Applications due July 25, 2022</li>
          <li>Large bridge projects: For projects with eligible costs over $100 million | Applications due August 9, 2022</li>
          <li>Bridge projects: For projects with eligible costs less than $100 million | Applications due September 8, 2022</li>
        </ul>

        Funds will be awarded on a competitive basis to projects that replace, rehabilitate, preserve and protect bridges on the National Bridge Inventory (NBI) by improving the safe and efficient movement of people and goods over bridges or by improving the condition of bridges in poor and at-risk conditions.
      </NewsContent>
    </NewsContainer>
  );
};

export default LatestNews;
